import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const newsBlock = document.querySelectorAll('.latest-news');

  if (0 < newsBlock.length) {
    newsBlock.forEach(function (newsCarousel) {
      new Swiper(newsCarousel.querySelector('.latest-news__inner.swiper'), {
        draggable: true,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 20,
        a11y: {
          paginationBulletMessage: '',
          nextSlideMessage: 'Prochaine nouvelle',
          prevSlideMessage: 'Nouvelle précédente',
        },
        navigation: {
          nextEl: newsCarousel
            .closest('.latest-news')
            .querySelector('.latest-news-button-next'),
          prevEl: newsCarousel
            .closest('.latest-news')
            .querySelector('.latest-news-button-prev'),
        },
        pagination: {
          el: newsCarousel
            .closest('.latest-news')
            .querySelector('.latest-news-pagination'),
          type: 'fraction',
        },
        breakpoints: {
          451: {
            slidesPerView: 1.5,
          },
          769: {
            slidesPerView: 2.5,
          },
          1200: {
            slidesPerView: 3,
          },
        },
      });
    });
  }
});
